import React from "react";

const StepperHeader = ( { heading, additionalClasses } ) => {
    return <header className={`text-xl text-center mb-4 ${additionalClasses}`} dangerouslySetInnerHTML={{__html: heading}}/>
}

StepperHeader.defaultProps = {
    additionalClasses: ''
}

export default StepperHeader;
