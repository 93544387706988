import React, { useState, useContext, useEffect } from "react";
import {
    CSSTransition,
    SwitchTransition,
} from 'react-transition-group';
import { StaticImage } from "gatsby-plugin-image";
import axios from "axios";
import { Context } from "../utils/store";

import StepperHeader from "./stepper/Header";
import PanelButton from "./stepper/PanelButton";
import PhoneNumber from "./PhoneNumber";
import { ArrowLeft } from "./icons/SvgIcons";
import { ProgressBar } from "./ProgressBar";

const Stepper = ( { steps, version } ) => {
    const [currentStep, setCurrentStep] = useState( 1 );
    const [showPhone, setShowPhone] = useState( false );
    //const [dataSent, setDataSent] = useState(false);
    const [errors, setErrors] = useState( {} );
    const [formData, setFormData] = useState( {
        who: "",
        reach: "",
        wheels: "",
        weight: "",
        availability: "",
        zip_code: "",
        prescription: "",
        email: "",
        name: "",
        number: "",
    } );
    const [state, dispatch] = useContext( Context );

    const totalSteps = steps.length;

    /**
     * Set timeout for number
     */
    useEffect( () => {
        let phoneTimer = setTimeout( () => {
            if ( ( currentStep > 1 && currentStep < totalSteps ) ) {
                setShowPhone( true )
                window.dataLayer.push( { event: 'phone-shown-after-5s-step-' + currentStep + '-' + version } );
            }
        }, 5000 );

        return () => {
            clearTimeout( phoneTimer );
        };
    }, [currentStep] );

    /**
     * Handle form change
     *
     * @param event
     */
    const handleChange = ( event ) => {
        setFormData( {
            ...formData,
            [event.target.name]: event.target.value,
        } );
    };

    /**
     * Handle first button select
     *
     * @param event
     */
    const handleStepOneSelect = ( event ) => {
        setFormData( {
            ...formData,
            [event.currentTarget.name]: event.currentTarget.value,
        } );

        next( event.currentTarget.name, event.currentTarget.value );

        if ( !state.show_modal ) {
            dispatch( {
                type: 'SHOW_MODAL',
                payload: true
            } );
        }
    }

    /**
     * Handle button select
     *
     * @param event
     */
    const handleSelect = ( event ) => {
        setFormData( {
            ...formData,
            [event.currentTarget.name]: event.currentTarget.value,
        } );

        next( event.currentTarget.name, event.currentTarget.value );
    }

    /**
     * Handle Enter-Key
     *
     * @param event
     */
    const handleKeyDown = ( event ) => {
        if ( event.key === 'Enter' ) {
            event.preventDefault();

            setFormData( {
                ...formData,
                [event.currentTarget.name]: event.currentTarget.value,
            } );

            if ( event.currentTarget.value.length >= 3 ) {
                next( event.currentTarget.name, event.currentTarget.value );
            }
        }
    }

    /**
     * Text Validation
     *
     * @param fieldName
     * @param value
     */
    const validateTextInput = ( fieldName, value ) => {
        switch ( fieldName ) {
            case 'email':
                const emailValid = value.match( /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i );
                setErrors( {
                    ...errors,
                    email: emailValid ? '' : 'E-Mail ist nicht korrekt.'
                } )
                break;
            case 'zip_code':
                const zipValid = value.length >= 5 && !isNaN( value );
                setErrors( {
                    ...errors,
                    zip_code: zipValid ? '' : 'PLZ ist nicht korrekt.'
                } )
                break;
            case 'name':
                const nameValid = isNaN( value );
                setErrors( {
                    ...errors,
                    name: nameValid ? '' : 'Name ist nicht korrekt.'
                } )
                break;
            case 'number':
                const numberValid = !isNaN( value );
                setErrors( {
                    ...errors,
                    number: numberValid ? '' : 'Telefonnummer ist nicht korrekt.'
                } )
                break;
            default:
                break;
        }
    }

    /**
     * Move to next step
     *
     * @param key
     * @param value
     * @param shouldSubmitForm
     */
    const next = ( key = '', value = '', shouldSubmitForm = false ) => {
        setCurrentStep( currentStep + 1 );
        setShowPhone( false );
        window.dataLayer.push( { event: 'next-step-' + ( currentStep + 1 ) + '-' + version } );

        if ( key && value ) {
            window.dataLayer.push( { [key]: value } );
        }

        if ( shouldSubmitForm ) {
            let form = new FormData();

            form.append( "availability", formData['availability'] );
            form.append( "email", formData['email'] );
            form.append( "name", formData['name'] );
            form.append( "number", formData['number'] );
            form.append( "prescription", formData['prescription'] );
            form.append( "reach", formData['reach'] );
            form.append( "weight", formData['weight'] );
            form.append( "wheels", formData['wheels'] );
            form.append( "who", formData['who'] );
            form.append( "zip_code", formData['zip_code'] );
            form.append( "version", version );

            setTimeout( () => {
                axios( {
                    method: 'post',
                    url: 'https://elektromobilvergleich.com/submit.php',
                    data: form
                } ).then( ( response ) => {
                    window.dataLayer.push( { event: 'form-submit-success' + '-' + version } );
                }, ( error ) => {
                    console.error( error );
                    window.dataLayer.push( { event: 'form-submit-error' + '-' + version } );
                } );
            }, 1000 )
        }
    };

    /**
     * Move to previous step
     */
    const back = () => {
        setCurrentStep( currentStep - 1 );
        window.dataLayer.push( { event: 'previous-step-' + ( currentStep - 1 ) + '-' + version } );
        setShowPhone( true );
    };

    // Init view
    let view = '';

    const components = {
        "StepWho": <StepWho key={currentStep} handleInput={handleStepOneSelect} version={version}/>,
        "StepReach": <StepReach key={currentStep} handleInput={handleSelect} version={version}/>,
        "StepWheels": <StepWheels key={currentStep} handleInput={handleSelect} version={version}/>,
        "StepWeight": <StepWeight key={currentStep} handleInput={handleSelect} version={version}/>,
        "StepAvailability": <StepAvailability key={currentStep} handleInput={handleSelect} version={version}/>,
        "StepZip":
            <StepZip key={currentStep} handleInput={handleChange} next={next} data={formData} handleKeyDown={handleKeyDown} errors={errors} version={version}/>,
        "StepSearch": <StepSearch key={currentStep} next={next} version={version}/>,
        "StepPrescription": <StepPrescription key={currentStep} handleInput={handleSelect} version={version}/>,
        "StepMail":
            <StepMail key={currentStep} handleInput={handleChange} next={next} data={formData} handleKeyDown={handleKeyDown} validateTextInput={validateTextInput} errors={errors} version={version}/>,
        "StepName":
            <StepName key={currentStep} handleInput={handleChange} next={next} data={formData} handleKeyDown={handleKeyDown} validateTextInput={validateTextInput} errors={errors} version={version}/>,
        "StepNumber":
            <StepNumber key={currentStep} handleInput={handleChange} next={next} data={formData} handleKeyDown={handleKeyDown} validateTextInput={validateTextInput} errors={errors} version={version}/>,
        "StepDone": <StepDone key={currentStep} version={version}/>,
    };

    // switch (currentStep) {
    //     case 1:
    //         view = components[steps[currentStep - 1]]
    //         break;
    //     case 2:
    //         view = components[steps[currentStep - 1]]
    //         break;
    //     case 3:
    //         view = <StepWheels key={3} handleInput={handleSelect}/>;
    //         break;
    //     case 4:
    //         view = <StepWeight key={4} handleInput={handleSelect}/>;
    //         break;
    //     case 5:
    //         view = <StepAvailability key={5} handleInput={handleSelect}/>;
    //         break;
    //     case 6:
    //         view =
    //             <StepZip key={6} handleInput={handleChange} next={next} data={formData} handleKeyDown={handleKeyDown} errors={errors}/>;
    //         break;
    //     case 7:
    //         view = <StepSearch key={7} next={next}/>;
    //         break;
    //     case 8:
    //         view = <StepPrescription key={8} handleInput={handleSelect}/>;
    //         break;
    //     case 9:
    //         view =
    //             <StepMail key={9} handleInput={handleChange} next={next} data={formData} handleKeyDown={handleKeyDown} validateTextInput={validateTextInput} errors={errors}/>;
    //         break;
    //     case 10:
    //         view =
    //             <StepName key={10} handleInput={handleChange} next={next} data={formData} handleKeyDown={handleKeyDown} validateTextInput={validateTextInput} errors={errors}/>;
    //         break;
    //     case 11:
    //         view =
    //             <StepNumber key={11} handleInput={handleChange} next={next} data={formData} handleKeyDown={handleKeyDown} validateTextInput={validateTextInput} errors={errors}/>;
    //         break;
    //     default:
    //         view = <StepDone key={12}/>;
    // }

    view = components[steps[currentStep - 1]]

    view = <CSSTransition key={currentStep} timeout={500} classNames="fade">{view}</CSSTransition>;

    const is_first = ( currentStep === 1 );
    const is_last = ( currentStep === totalSteps );

    const percent = 100 * currentStep / totalSteps;

    return (
        <div className={`multistep`}>
            <form className={`views flex flex-wrap justify-items-center justify-center content-center p-3`}>
                <SwitchTransition mode={`out-in`}>
                    {view}
                </SwitchTransition>

                {( !is_first && !is_last ) && <PhoneNumber show={showPhone}/>} {( !is_first && !is_last ) &&
            <button onClick={back} type="button" className={`btn-wrap back-button`} aria-label="Zurück gehen">
                <ArrowLeft/> <span className="text">vorherige <br/> Frage</span></button>}
            </form>

            <ProgressBar percent={percent}/>
        </div>
    )
}

const StepWho = ( { handleInput, version } ) => {
    return (
        <div className={`form-step w-full step-who step-who-${version}`}>
            <StepperHeader heading="Für wen suchen Sie ein preisgünstiges Elektromobil?" additionalClasses={`heading-who heading-who-${version}`}/>

            <div className={`grid grid-cols-1 md:grid-cols-4 gap-2 items-center`}>
                <PanelButton handleInput={handleInput} name="who" value="Für mich" image="bg-mich" classes={`md:col-start-2 step-who-1 step-who-1-${version}`}/>

                <PanelButton handleInput={handleInput} name="who" value="Für andere" image="bg-andere" classes={`step-who-2 step-who-2-${version}`}/>
            </div>
        </div>
    )
}

const StepReach = ( { handleInput, version } ) => {
    return (
        <div className={`form-step w-full step-reach step-reach-${version}`}>
            <StepperHeader heading="Wie weit möchten Sie mit dem Elektromobil fahren?" additionalClasses={`heading-reach heading-reach-${version}`}/>

            <div className={`grid grid-cols-2 md:grid-cols-8 gap-2 items-center`}>
                <PanelButton handleInput={handleInput} name="reach" value="bis zu 15 Km" image="bg-15km" classes={`md:col-start-2 md:col-span-2 step-reach-1 step-reach-1-${version}`}/>

                <PanelButton handleInput={handleInput} name="reach" value="bis zu 30 Km" image="bg-30km" classes={`md:col-span-2 step-reach-2 step-reach-2-${version}`}/>

                <PanelButton handleInput={handleInput} name="reach" value="Weiß nicht" image="bg-nicht_sicher" classes={`md:col-span-2 step-reach-3 step-reach-3-${version}`}/>
            </div>
        </div>
    )
}

const StepWheels = ( { handleInput, version } ) => {
    return (
        <div className={`form-step w-full step-wheels step-wheels-${version}`}>
            <StepperHeader heading="Wie viele Räder soll Ihr Elektromobil haben?" additionalClasses={`heading-wheels heading-wheels-${version}`}/>

            <div className={`grid grid-cols-2 md:grid-cols-4 gap-2 items-center`}>
                <PanelButton handleInput={handleInput} name="wheels" value="3 Räder" image="bg-drei_raeder" classes={`md:col-start-2 step-wheels-1 step-wheels-1-${version}`}/>

                <PanelButton handleInput={handleInput} name="who" value="4 Räder" image="bg-vier_raeder" classes={`step-wheels-2 step-wheels-2-${version}`}/>
            </div>
        </div>
    )
}

const StepWeight = ( { handleInput, version } ) => {
    return (
        <div className={`form-step w-full step-weight step-weight-${version}`}>
            <StepperHeader heading="In welchem Bereich liegt das Nutzergewicht?" additionalClasses={`heading-weight heading-weight-${version}`}/>

            <div className={`grid grid-cols-2 md:grid-cols-4 gap-2 items-center`}>
                <PanelButton handleInput={handleInput} name="weight" value="Bis 90kg" image="bg-90kg" classes={`step-weight-1 step-weight-1-${version}`}/>

                <PanelButton handleInput={handleInput} name="weight" value="Bis 160kg" image="bg-160kg" classes={`step-weight-2 step-weight-2-${version}`}/>

                <PanelButton handleInput={handleInput} name="weight" value="Mehr als 160kg" image="bg-160pluskg" classes={`step-weight-3 step-weight-3-${version}`}/>

                <PanelButton handleInput={handleInput} name="weight" value="Weiß nicht" image="bg-nicht_sicher" classes={`step-weight-4 step-weight-4-${version}`}/>
            </div>
        </div>
    )
}

const StepAvailability = ( { handleInput, version } ) => {
    return (
        <div className={`form-step w-full step-availability step-availability-${version}`}>
            <StepperHeader heading="Wann benötigen Sie das Elektromobil?" additionalClasses={`heading-availability heading-availability-${version}`}/>

            <div className={`grid grid-cols-2 md:grid-cols-8 gap-2 items-center`}>
                <PanelButton handleInput={handleInput} name="availability" value="Schnellstmöglich" image="bg-schnellstmoeglich" classes={`md:col-start-2 md:col-span-2 step-availability-1 step-availability-1-${version}`}/>

                <PanelButton handleInput={handleInput} name="availability" value="Später" image="bg-spaeter" classes={`md:col-span-2 step-availability-2 step-availability-2-${version}`}/>

                <PanelButton handleInput={handleInput} name="availability" value="Weiß nicht" image="bg-nicht_sicher" classes={`md:col-span-2 step-availability-3 step-availability-3-${version}`}/>
            </div>
        </div>
    )
}

const StepZip = ( { handleInput, next, data, handleKeyDown, errors, version } ) => {
    return (
        <div className={`form-step w-full step-zip step-zip-${version}`}>
            <StepperHeader heading="In welchem PLZ-Gebiet leben Sie?" additionalClasses={`heading-zip heading-zip-${version}`}/>

            <div className={`grid grid-cols-2 md:grid-cols-4 gap-2 items-center`}>
                <div className={`col-span-4 md:col-span-2 md:col-start-2`}>
                    <input type="number" onChange={handleInput} onKeyDown={handleKeyDown} placeholder={`54321`} name={`zip_code`} className={`form-component ${errors.zip_code ? 'border-secondary' : ''}`} required={true} value={data.zip_code}/>
                    <button onClick={() => next( `zip_code`, data.zip_code )} type="button" className={`btn btn-primary btn-block w-full mb-1 step-zip-1 step-zip-1-${version}`} disabled={data.zip_code.length < 5}>Weiter</button>
                </div>
            </div>
        </div>
    )
}

const StepSearch = ( { next, version } ) => {
    let [showLoader, setShowLoader] = useState( true );
    let [hasResults, setHasResults] = useState( false );

    setTimeout( () => {
        setShowLoader( false )
        setHasResults( true )
    }, 2500 )

    return (
        <div className={`form-step w-full step-offer step-offer-${version}`}>
            {hasResults ?
                <StepperHeader heading="Wir haben 8 Angebote für Sie gefunden." additionalClasses={`heading-offer-found heading-offer-found-${version}`}/> :
                <StepperHeader heading="Wir vergleichen für Sie und finden die besten Angebote" additionalClasses={`heading-offer-search heading-offer-search-${version}`}/>}

            <div className={`grid grid-cols-2 md:grid-cols-4 gap-2 items-center`}>
                <div className={`col-span-4 md:col-span-4 text-center`}>
                    <div className="img-wrap relative">
                        <div className="loading-screen flex flex-wrap justify-items-center justify-center content-center mb-4 text-center">
                            {showLoader && <div className="loader h-10 w-10 absolute-center">
                                <svg className="animate-spin h-10 w-10 z-50 text-primary" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"/>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/>
                                </svg>
                            </div>}

                            {hasResults && <div>
                                <button onClick={next} type="button" className={`btn btn-primary btn-wide btn-lg step-offer-1 step-offer-1-${version}`}>Weiter</button>
                            </div>}
                        </div>

                        <StaticImage src="../images/logos.png" width={3562} height={168} alt="Elektromobilvergleich Logo"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

const StepPrescription = ( { handleInput, version } ) => {
    return (
        <div className={`form-step w-full step-prescription step-prescription-${version}`}>
            <StepperHeader heading="Haben Sie ein Kassenrezept?" additionalClasses={`heading-prescription heading-prescription-${version}`}/>

            <div className={`grid grid-cols-2 md:grid-cols-8 gap-2 items-center`}>
                <PanelButton handleInput={handleInput} name="prescription" value="Ja" image="bg-mit_rezept" classes={`md:col-start-2 md:col-span-2 step-prescription-1 step-prescription-1-${version}`}/>

                <PanelButton handleInput={handleInput} name="prescription" value="Nein" image="bg-ohne_rezept" classes={`md:col-span-2 step-prescription-2 step-prescription-2-${version}`}/>

                <PanelButton handleInput={handleInput} name="prescription" value="Weiß nicht" image="bg-nicht_sicher" classes={`md:col-span-2 step-prescription-3 step-prescription-3-${version}`}/>
            </div>
        </div>
    )
}

const StepMail = ( { handleInput, next, data, handleKeyDown, version } ) => {
    const validateEmail = ( email ) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test( String( email ).toLowerCase() );
    }

    const prevDef = ( e ) => {
        if ( e.key === 'Enter' ) {
            e.preventDefault()
        }
    }

    let isValid = validateEmail( data.email );

    return (
        <div className={`form-step w-full step-email step-email-${version}`}>
            <StepperHeader heading="An welche E-Mail-Adresse sollen wir Ihr Vergleichsangebot senden?" additionalClasses={`heading-email heading-email-${version}`}/>

            <div className={`grid grid-cols-2 md:grid-cols-4 gap-2 items-center`}>
                <div className={`col-span-4 md:col-span-2 md:col-start-2`}>
                    <input type="email" onChange={handleInput} onKeyDown={( e ) => {
                        isValid ? handleKeyDown( e ) : prevDef( e )
                    }} placeholder={`info@beispiel.de`} name={`email`} className={`form-component`} required={true} value={data.email}/>
                    <button onClick={() => next( `email`, data.email )} type="button" className={`btn btn-primary btn-block w-full mb-1 step-email-1 step-email-1-${version}`} disabled={ !isValid}>Weiter</button>
                </div>
            </div>
        </div>
    )
}

const StepName = ( { handleInput, next, data, handleKeyDown, version } ) => {
    const validateName = ( name ) => {
        const re = /^[\u00C0-\u017Fa-zA-Z ]+$/;
        return re.test( String( name ).toLowerCase() ) && name.length >= 3;
    }

    const prevDef = ( e ) => {
        if ( e.key === 'Enter' ) {
            e.preventDefault()
        }
    }

    let isValid = validateName( data.name );

    return (
        <div className={`form-step w-full step-name step-name-${version}`}>
            <StepperHeader heading="Bitte geben Sie Ihre Namen ein" additionalClasses={`heading-name heading-name-${version}`}/>

            <div className={`grid grid-cols-2 md:grid-cols-4 gap-2 items-center`}>
                <div className={`col-span-4 md:col-span-2 md:col-start-2`}>
                    <input type="text" onChange={handleInput} onKeyDown={( e ) => {
                        isValid ? handleKeyDown( e ) : prevDef( e )
                    }} placeholder={`Max Mustermann`} name={`name`} className={`form-component`} required={true} value={data.name}/>
                    <button onClick={() => next( `name`, data.name )} type="button" className={`btn btn-primary btn-block w-full mb-1 step-name-1 step-name-1-${version}`} disabled={( !isValid )}>Weiter</button>
                </div>
            </div>
        </div>
    )
}

const StepNumber = ( { handleInput, next, data, handleKeyDown, version } ) => {
    return (
        <div className={`form-step w-full step-phone step-phone-${version}`}>
            <StepperHeader heading="Geben Sie jetzt Ihre Telefonnummer ein <br/> (um Ihre gratis Probefahrt terminieren zu können)" additionalClasses={`heading-phone heading-phone-${version}`}/>

            <div className={`grid grid-cols-2 md:grid-cols-4 gap-2 items-center`}>
                <div className={`col-span-4 md:col-span-2 md:col-start-2`}>
                    <input type="number" onChange={handleInput} onKeyDown={handleKeyDown} placeholder={`0234 567890`} name={`number`} className={`form-component`} required={true} value={data.number}/>
                    <button onClick={() => next( `number`, data.number, true )} type="button" className={`btn btn-primary btn-block w-full mb-1 step-phone-1 step-phone-1-${version}`} disabled={data.number.length < 5}>Weiter</button>
                </div>
            </div>
        </div>
    )
}

const StepDone = ( { version } ) => {
    return (
        <div className={`form-step w-full step-thanks step-thanks-${version}`}>
            <StepperHeader heading="Vielen Dank für Ihre Anfrage – Sie haben es fast geschafft" additionalClasses={`heading-thanks heading-thanks-${version}`}/>

            <div className={`grid grid-cols-2 md:grid-cols-4 gap-2 items-center mb-3`}>
                <div className={`col-span-4 md:col-span-2`}>
                    <h3 className={`mb-2`}>Das sind die nächsten Schritte:</h3>
                    <ol className={`list-decimal list-outside pl-1 text-lg`}>
                        <li>Sie erhalten einen Anruf mit dem besten Preisangebot</li>
                        <li>Sie haben Anspruch auf eine kostenlose Probefahrt</li>
                        <li>Sie können sich in Ruhe für die beste Option entscheiden</li>
                    </ol>
                </div>
                <div className={`col-span-4 md:col-span-2`}>
                    <StaticImage src="../images/mitarbeiter.jpg" width={816} height={514} alt="A Gatsby astronaut" className={`mx-auto mb-3 w-full`}/>
                </div>
            </div>

            <div className={`grid grid-cols-1 items-center`}>
                <button type="button" onClick={() => window.location.reload()} className={`btn btn-secondary mt-3 step-thanks-1 step-thanks-1-${version}`}>Abschließen</button>
            </div>
        </div>
    )
}

export default Stepper;
